<template>
  <div class="weui-cells">
    <country :text="text" :model.sync="item.country">
      <span
        class="position"
        @click.stop.prevent="addPosition"
        v-if="inChina == 0 && !env"
      >
        <i class="el-icon-map-location"></i>
        地图</span
      >
    </country>
    <!-- <apicker
      :options="countries"
      :text="text"
      
      placeholder="请选择"
      :required="true"
    >
      <span
        class="position"
        @click.stop.prevent="addPosition"
        v-if="inChina == 0 &&!env"
      >
        <i class="el-icon-map-location"></i>
        地图</span
      >
    </apicker> -->
    <template v-if="inChina == 0">
      <apicker
        :options="provinces"
        type="text"
        text="省"
        :model.sync="item.province"
        value=""
        placeholder="省"
      >
      </apicker>
      <apicker
        :options="cities"
        type="text"
        text="市"
        :model.sync="item.city"
        value=""
        placeholder="市"
      >
      </apicker>
      <apicker
        :options="districts"
        type="text"
        text="区"
        :model.sync="item.district"
        value=""
        placeholder="区"
      >
      </apicker>
    </template>
    <ainput
      type="text"
      text="详细地址"
      :model.sync="item.aimAddress"
      value=""
      placeholder="详细地址"
      :required="true"
    >
    </ainput>
    <!-- 地图选点 -->
    <div v-if="mapFlag" class="map">
      <iframe
        id="mapPage"
        width="100%"
        height="100%"
        frameborder="0"
        allow="geolocation"
        src="https://apis.map.qq.com/tools/locpicker?search=1&type=1&key=MLMBZ-JXJCW-TXVR3-OF7AG-ZD5CF-ONFBC&referer=myapp"
      >
      </iframe>
    </div>
  </div>
</template>

<script>
import ainput from "@/components/form/input";
import apicker from "@/components/form/picker";
import country from "@/components/form/country";
import { getAllcounrtyData } from "@/api/api";
import { regionData } from "element-china-area-data";
export default {
  props: {
    defalutForm: {
      type: Object,
      default: () => {
        return {
          country: "",
          province: "",
          city: "",
          district: "",
          aimAddress: "",
        };
      },
    },
    text: {
      type: String,
      default: "目的地国家",
    },
  },
  mounted() {
    this.getCountries();
    this.env = window.__wxjs_environment === "miniprogram";
    if (!this.env) {
      this.mapListener();
    }
    this.stopReset = true;
    this.item = this.defalutForm;
    this.provinces = this.regionData.map((item) => {
      return {
        label: item.label,
        value: item.label,
      };
    });
  },
  data() {
    return {
      item: {
        country: "",
        province: "",
        city: "",
        district: "",
        aimAddress: "",
      },
      target: {},
      countries: [],
      inChina: 1,
      mapFlag: false,
      provinces: [],
      regionData,
      stopReset: false,
    };
  },
  computed: {
    cities() {
      let flag = this.regionData.some((item) => {
        return item.label == this.item.province;
      });
      if (flag) {
        let arr = this.regionData
          .find((item) => {
            return item.label == this.item.province;
          })
          .children.map((item) => {
            return {
              label: item.label,
              value: item.label,
            };
          });
        return arr;
      } else {
        return [];
      }
    },
    districts() {
      let result = this.item.province
        ? this.regionData.find((item) => {
            return item.label == this.item.province;
          }).children
        : [];
      let flag = result.some((item) => {
        return item.label == this.item.city;
      });
      if (flag) {
        let arr = result
          .find((item) => {
            return item.label == this.item.city;
          })
          .children.map((item) => {
            return {
              label: item.label,
              value: item.label,
            };
          });
        return arr;
      } else {
        return [];
      }
    },
  },
  methods: {
    async getCountries() {
      let res = await getAllcounrtyData();
      this.countries = res.data.data.map((item) => {
        return {
          label: item.name,
          value: item.name,
        };
      });
    },
    // 调起地图
    addPosition(index) {
      this.mapFlag = true;
      this.currentIndex = index;
    },
    mapListener() {
      let _this = this;
      window.addEventListener(
        "message",
        function (event) {
          // 接收位置信息，用户选择确认位置点后选点组件会触发该事件，回传用户的位置信息
          var loc = event.data;
          if (loc && loc.module == "locationPicker") {
            //防止其他应用也会向该页面post信息，需判断module是否为'locationPicker'
            _this.mapFlag = false;
            let item = {};
            item.latLng = loc.latlng.lat + "," + loc.latlng.lng;
            _this
              .$jsonp(
                "https://apis.map.qq.com/ws/geocoder/v1/?key=MLMBZ-JXJCW-TXVR3-OF7AG-ZD5CF-ONFBC&output=jsonp&location=" +
                  [loc.latlng.lat, loc.latlng.lng].join(",")
              )
              .then((res) => {
                if (res) {
                  _this.$set(
                    _this.item,
                    "province",
                    res.result.ad_info.province
                  );
                  _this.$set(
                    _this.item,
                    "city",
                    res.result.ad_info.city == res.result.ad_info.province
                      ? "市辖区"
                      : res.result.ad_info.city
                  );
                  _this.$set(
                    _this.item,
                    "district",
                    res.result.ad_info.district
                  );
                  _this.$set(
                    _this.item,
                    "aimAddress",
                    res.result.formatted_addresses.recommend
                  );
                  _this.mapFlag = false;
                  _this.stopReset = true;
                  _this.item.inChina = _this.inChina;
                  _this.$emit("change", _this.item);
                }
              });
          }
        },
        false
      );
    },
  },
  watch: {
    "item.country": function (val) {
      this.stopReset = true;
      this.inChina = val == "中国" ? 0 : 1;
      //   this.stopReset&&(this.item.aimAddress = '')
    },
    "item.province": function () {
      if (!this.stopReset) {
        this.item.city = "";
        this.item.district = "";
      }
    },
    "item.city": function (val, oval) {
      if (!this.stopReset) {
        this.item.district = "";
      }
      this.stopReset = false;
    },

    item: {
      deep: true,
      handler: function () {
        this.item.inChina = this.inChina;
        this.$emit("change", this.item);
      },
    },
  },
  components: {
    ainput,
    apicker,
    country,
  },
};
</script>

<style scoped>
.add {
  font-size: 0.14rem;
  color: #2f7dcd;
  text-align: center;
  margin-top: 0.1rem;
}
.position {
  color: #2f7dcd;
  margin-left: 0.1rem;
  width: 0.6rem;
  font-size: 0.12rem;
  background: #2f7dcd;
  color: #fff;
  padding: 3px 5px;
  text-align: center;
  border-radius: 5px;
  flex-shrink: 0;
}
.map {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>