<template>
  <div class="form">
    <div class="weui-cells">
      <apicker
        :options="types"
        text="隔离类型"
        :model.sync="form.typeId"
        placeholder="请选择"
      ></apicker>
    </div>
    <!-- 组1 -->
    <div class="weui-cells__title">隔离时间</div>
    <div class="weui-cells">
      <adate
        text="隔离开始时间"
        :model.sync="form.startTime"
        placeholder="请选择"
      ></adate>
      <adate
        text="预计结束时间"
        :model.sync="form.endTime"
        placeholder="请选择"
      ></adate>
    </div>
    <!-- 组2 -->
    <div class="weui-cells">
      <aswitch
        text="是否密切接触者"
        :model.sync="form.isClose"
        placeholder="请选择"
      ></aswitch>
      <aswitch
        text="是否次密切接触者"
        :model.sync="form.isSubClose"
        placeholder="请选择"
      ></aswitch>
    </div>
    <common-position
      @change="choosePostion"
      :defalutForm="position"
      text="隔离地点"
    ></common-position>
    <div class="btn-area">
      <button class="weui-btn weui-btn_primary" @click="submit">确认</button>
    </div>
    <section class="historyBox" v-if="!isEdit">
      <p class="trigger">隔离记录</p>
      <el-timeline class="timeline">
        <el-timeline-item
          v-for="(item, index) in list"
          :key="index"
          :timestamp="
            item.startTime.substr(0, 10) + '-' + item.endTime.substr(0, 10)
          "
          style="position: relative"
        >
          <p>隔离类型：{{ item.isolateType ? item.isolateType : "" }}</p>
          <p >隔离地点：{{ item.abroadName + (item.province||'') + (item.city||'') + (item.district||'') +item.addressDetail }}</p>
        </el-timeline-item>
      </el-timeline>
      <p class="viewAllBtn" @click="goHistory" v-if="!isEdit">点击查看全部</p>
    </section>
  </div>
</template>

<script>
import ainput from "@/components/form/input";
import apicker from "@/components/form/picker";
import adate from "@/components/form/datepicker";
import aswitch from "@/components/form/switch";
import aradio from "@/components/form/radio";
import location from "@/mixins/getLocation";
import acell from "@/components/form/cell";
import {
  IsolateSave,
  IsolateType,
  countryList,
  getIsolateDetail,
  EditIsolate,
} from "@/api/api";
import VDistpicker from "v-distpicker";
import time from "@/mixins/time";
import webview from "@/mixins/webview";
import commonPosition from "@/components/common/position";
export default {
  data() {
    return {
      name: "",
      types: [],
      radios: [
        { label: "境内", value: 0 },
        { label: "境外", value: 1 },
      ],
      form: {
        mobile: this.$store.state.userInfo.mobile,
        typeId: "",
        startTime: this.nowTime(),
        endTime: this.nowTime(),
        addressDetail: "",
        createTime: "",
        isVaccines: 0,
        isClose: 0,
        isSubClose: 0,
        isVillageIsolate: 0,
        inChina: 0,
      },
      position: {},
      mapFlag: false,
      countries: [],
      list: [],
      isEdit: false,
    };
  },
  components: {
    ainput,
    apicker,
    adate,
    acell,
    VDistpicker,
    aswitch,
    aradio,
    commonPosition,
  },
  mixins: [location, time, webview],
  mounted() {
    this.isWebView();
    this.getCountries();
    let _this = this;
    this.getLocation();
    this.getTypes();
    this.getHistoryList();
  },
  created() {
    this.isEdit = Boolean(this.$route.query.isEdit);
    document.title = this.isEdit ? "编辑隔离记录" : "隔离登记";
    this.$route.query.data && (this.form = JSON.parse(this.$route.query.data));
    this.position = {
      country: this.form.abroadName ? this.form.abroadName : "中国",
      province: this.form.province,
      city: this.form.city,
      district: this.form.district,
      aimAddress:this.form.addressDetail
    };
    this.id = this.$route.query.id;
  },
  methods: {
    goHistory() {
      let mobile = this.$store.state.userInfo.mobile;
      this.$router.push("/detailList/" + mobile + "?type=isolate");
    },
    async getCountries() {
      let res = await countryList();
      this.countries = res.data.data.map((item) => {
        return {
          label: item.name,
          value: item.name,
        };
      });

    },
    async getHistoryList() {
      let phone = this.$store.state.userInfo.mobile;
      let res = await getIsolateDetail(phone);
      this.list = res.data.data.slice(0, 3);
    },
    mapSelect(data) {
      this.province = data.province.value;
      this.city = data.city.value;
      this.district = data.area.value;
    },
    submit() {
      this.form.isClose = this.form.isClose ? 1 : 0;
      this.form.isVillageIsolate = this.form.isVillageIsolate ? 1 : 0;
      this.form.isSubClose = this.form.isSubClose ? 1 : 0;
      this.form.createTime = this.formatTime();
      let flag = true;
      let tips = "";
      for (let key in this.form) {
        console.log(key);
        if (this.form[key] == "") {
          switch (key) {
            case "typeId":
              tips = "隔离类型为必填";
              flag = false;
              break;
            case "startTime":
              tips = "隔离开始时间为必填";
              flag = false;
              break;
            case "endTime":
              tips = "隔离结束时间为必填";
              flag = false;
              break;
          }
        }
      }
      if (!flag) {
        this.$weui.toast(tips, {
          duration: 1500,
          className: "error",
        });
        return;
      }
      if (this.id) {
        EditIsolate(this.id, this.form).then((res) => {
          this.$weui.toast("提交成功", {
            duration: 1500,
            className: "custom-classname",
          });
          if (window.__wxjs_environment === "miniprogram") {
            setTimeout(() => {
              wx.miniProgram.navigateBack();
            }, 2000);
          } else {
            this.$router.go(-1);
          }
        });
      } else {
        IsolateSave(this.form).then((res) => {
          this.$weui.toast("提交成功", {
            duration: 1500,
            className: "custom-classname",
          });
          if (window.__wxjs_environment === "miniprogram") {
            setTimeout(() => {
              wx.miniProgram.navigateBack();
            }, 2000);
          } else {
            this.$router.go(-1);
          }
        });
      }
    },
    async getTypes() {
      let res = await IsolateType();
      let arr = res.data.data.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      this.types = arr;
    },
    formatTime() {
      let date = new Date();
      let y = date.getFullYear();
      let m = this.format(date.getMonth() + 1);
      let d = this.format(date.getDate());
      return `${y}-${m}-${d} 00:00:00`;
    },
    format(num) {
      return num < 10 ? "0" + num : num;
    },
    choosePostion(e) {
      console.log(e);
      this.form.abroadName = e.country;
      this.form.province = e.province;
      this.form.city = e.city;
      this.form.district = e.district;
      this.form.addressDetail = e.aimAddress;
      this.form.inChina = e.inChina;
    },
  },
};
</script>

<style scoped>
.position {
  color: #2f7dcd;
  margin-left: 0.1rem;
  width: 0.6rem;
  font-size: 0.12rem;
  background: #2f7dcd;
  color: #fff;
  padding: 3px 5px;
  text-align: center;
  border-radius: 5px;
}
.trigger {
  margin: 0.1rem 0;
  color: #2f7dcd;
  font-size: 0.14rem;
  /* text-align: right; */
}
.timeline {
  overflow: hidden;
}
.historyBox {
  padding: 0.1rem 0.2rem 0rem;
}
</style>