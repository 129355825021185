<template>
  <section @touch.prevent.stop>
    <div class="weui-cell">
      <div class="weui-cell__bd">{{ text }}</div>
      <div class="weui-cell__ft" @click="listFlag = true">
        <span>{{ input }}</span>
        <slot></slot>
      </div>
    </div>
    <div
      class="list-box"
      v-if="listFlag"
      @click.stop.prevent="listFlag = false"
    >
      <div class="list-box_cont" ref="listCount">
        <ul
          v-for="item in list"
          :key="item.letter"
          :ref="item.letter"
          class="list-item"
          v-if="item.children.length"
        >
          <li class="list-box_title">{{ item.letter }}</li>
          <li
            v-for="child in item.children"
            :key="child.id"
            @click="choose(child)"
          >
            {{ child.name }}
          </li>
        </ul>
      </div>
      <p class="list-letters">
        <span
          v-for="item in list"
          :key="item.letter"
          @click.stop.prevent="scrollTop(item.letter)"
          class="list-item"
          v-if="item.children.length"
          >{{ item.letter }}</span
        >
      </p>
    </div>
  </section>
</template>

<script>
import country from "@/components/form/country";
import { getAllcounrtyData } from "@/api/api";
const letters = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
    model: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      letters,
      list: [],
      listFlag: false,
      input: "请选择",
    };
  },
  created() {
    this.getList();
    this.model && (this.input = this.model);
  },
  watch: {
    model(val) {
      val && (this.input = val);
    },
  },
  methods: {
    choose(item) {
        console.log(item)
      this.$emit("update:model", item.name);
    },
    async getList() {
      let res = await getAllcounrtyData();
      let list = letters.map((item) => {
        return {
          letter: item,
          children: [],
        };
      });
      res.data.data.forEach((item) => {
        let letter = this.getCnChar(item.name);
        let idx = list.findIndex((item) => item.letter == letter);
        list[idx].children.push(item);
      });
      this.list = list;
    },
    getCnChar(str) {
      return str.spell().substr(0, 1);
    },
    scrollTop(id) {
      let scroll = this.$refs.listCount;
      if (this.$refs[id].length) {
        let top = this.$refs[id][0].offsetTop;
        scroll.scrollTop = top;
        // window.scrollTo(0,top)
      }
    },
  },
};
</script>

<style scoped>
.list-box {
  position: fixed;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 999;
  height: 100%;

  width: 100%;
  box-shadow: 0 0 10px #efefef;
}
.list-box_cont {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 80%;
  animation: slideFromBottom 0.2s ease-out;
  background: #fff;
  overflow: scroll;
}
.list-box_title {
  background: #2f7dcd;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
}
.list-item {
  list-style: none;
  /* padding: 0.2rem; */
}
.list-item li {
  border-bottom: 1px solid #e6e6e6;
  padding: 0.1rem 0.2rem;
  font-size: 13px;
}
.list-item li:active{
    color: #2f7dcd;
}
.list-letters {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  right: 15px;
  top: 50%;
  transform: translateY(-40%);
  font-size: 14px;
  width: 30px;
  text-align: right;
  color: #666;
  animation: slideFromBottomToCenter 0.2s ease-out;
}
.list-letters span {
  margin: 2px 0;
}
@keyframes slideFromBottom {
    0%{
        height: 0;
    }
    100%{
        height: 80%;
    }
}
@keyframes slideFromBottomToCenter {
    0%{
        top: 100%;
    }
    100%{
        top: 50%;
    }
}
</style>